














































































// Core
import {Component, Vue} from 'vue-property-decorator'
import {namespace} from 'vuex-class'

// Interfaces
import {Project, ProjectsState} from '@store/projects/interfaces'
import {ProjectsActions} from '@store/projects/types'

const NSProjects = namespace('projectsModule')

@Component({
  name: 'Project',

  components: {
    'v-user-card': () => import('./components/UserCard.vue'),
    'v-dialog-select-user': () => import('./components/DialogSelectUser.vue'),
    'v-no-content': () => import('@/components/NoContent.vue'),
  },
})
export default class ProjectComponent extends Vue {
  @NSProjects.State((state: ProjectsState) => state.project)
  private project!: Project

  @NSProjects.Action(ProjectsActions.A_GET_PROJECT)
  private fetchProject!: (projectId: number) => Promise<Project>

  @NSProjects.Action(ProjectsActions.A_CONNECT_PROJECT_TO_USER)
  private connectProjectToUser!: (payload: { userId: number; projectId: number; roleId: number }) => Promise<any>

  @NSProjects.Action(ProjectsActions.A_DISCONNECT_PROJECT_TO_USER)
  private disconnectProjectToUser!: (payload: { userId: number; projectId: number; roleId: number }) => Promise<any>

  private visibleDialogSelectUser: boolean = false

  private projectId
  private roleId: number | null = null
  private loading: boolean = false

  protected created() {
    this.projectId = parseInt(this.$route.params.projectId, 10)
    this.updateProject()
  }

  private async handleConnectProjectToUser(userId: number) {
    return this.connectProjectToUser({projectId: this.projectId, userId, roleId: this.roleId as number})
      .then(() => {
        this.$successNotify()
        this.visibleDialogSelectUser = false
        this.updateProject()
      })
      .catch(error => this.$errorNotify(error?.response?.data?.message))
  }

  private handleDisconnectProjectToUser(userId: number) {
    this.loading = true
    this.disconnectProjectToUser({projectId: this.projectId, userId, roleId: this.roleId as number})
      .then(() => {
        this.$successNotify()
        return this.updateProject()
      })
      .catch(error => this.$errorNotify(error?.response?.data?.message))
      .finally(() => this.loading = false)
  }

  private async updateProject() {
    this.loading = true
    await this.fetchProject(this.projectId)
    this.loading = false
  }
}
